import React from 'react'
import Helmet from 'react-helmet'

import config from '../../data/site-config'

import Layout from '../components/layout'
import MainWrapper from '../components/MainWrapper/MainWrapper'
import PageHero from '../components/PageHero'
import ContactForm from '../components/Forms/NetlifyContactForm'

class ContactPage extends React.Component {

  render() {
    return (
      <Layout className="bg-fog-white">

        <Helmet
          title={`📪 Contact Us • Hire a Web Designer • Spacetime Web Design`}
          meta={[
            { name: 'description', content: 'Hire the right web designer for you. Contact us for a FREE consulation about your Web Design, Development, and User Experience needs.' }
          ]}
          link={[
            { rel: 'canonical', href: `${config.siteUrl}/contact/` }
          ]}
        />

        <div>

        <PageHero>
            <h1 className="t-headline">Hire the right web designer for you&mdash;<span className="o-60">or contact us to shoot the breeze</span></h1>
          </PageHero>

          <MainWrapper>

            <section>
              <div className="row cf flex justify-center">

                <div className="w-100 w-80-ns mw6 mv5 mv6-l">
                  <h3 className="f2 mb5">Contact us</h3>
                  <ContactForm />
                </div>

              </div>
            </section>

          </MainWrapper>
        </div>
      </Layout>
    );
  }
}

export default ContactPage
